














import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OParticipantMessages: () =>
      import(
        "@/components/organisms/event/participant/o-participant-messages.vue"
      ),
  },
});
